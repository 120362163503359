import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { AnimatePresence } from "framer-motion"

import Footer from "./footer"
import Header from "./header"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div
        style={{
          margin: `0 auto`,
          maxWidth: `var(--size-content)`,
          padding: `var(--size-gutter)`,
        }}
      >
        <Header />
        <AnimatePresence exitBeforeEnter>
          <main>{children}</main>
        </AnimatePresence>

        <Footer />
      </div>
    </>
  )
}

export default Layout
