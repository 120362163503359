exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-affliates-js": () => import("./../../../src/pages/affliates.js" /* webpackChunkName: "component---src-pages-affliates-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/FAQ.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-how-to-be-a-better-client-js": () => import("./../../../src/pages/how-to-be-a-better-client.js" /* webpackChunkName: "component---src-pages-how-to-be-a-better-client-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-introductory-appointment-js": () => import("./../../../src/pages/introductory-appointment.js" /* webpackChunkName: "component---src-pages-introductory-appointment-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-statement-js": () => import("./../../../src/pages/privacy-statement.js" /* webpackChunkName: "component---src-pages-privacy-statement-js" */),
  "component---src-pages-service-map-area-js": () => import("./../../../src/pages/service-map-area.js" /* webpackChunkName: "component---src-pages-service-map-area-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-useful-links-js": () => import("./../../../src/pages/useful-links.js" /* webpackChunkName: "component---src-pages-useful-links-js" */)
}

