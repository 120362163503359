import React from "react"
import { Link } from "gatsby"

export default function Example() {
  return (
    <footer className="bg-white">
      <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
        <div className="mt-8 md:order-1 md:mt-0">
          <p className="text-center text-base text-gray-400">
          &copy; {new Date().getFullYear()} Dial A Minder. {" | "}{" "}
            <Link className="hover:text-primary" to="/privacy-statement">
              Privacy
            </Link>{" "}
            {" | "}{" "}
            <Link className="hover:text-primary" to="/terms-of-use">
              Terms of Use
            </Link>
          </p>
        </div>
      </div>
    </footer>
  )
}
